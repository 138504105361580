import styled, { css } from 'styled-components'

import { BREAKPOINTS } from './grid'
import Spacing from './spacing'

const rem = (pixels) => {
    return `${pixels / baseFontSize}rem`
}

const baseFontSize = '16'

const typescale = {
    cover: rem(260),
    display: rem(90),
    h1: rem(84),
    h2: rem(60),
    h3: rem(46),
    h4: rem(28),
    h5: rem(21),
    h6: rem(20),
    body: rem(20),
    subtitle: rem(16),
    button: rem(20),
    caption: rem(14),
}

const lineHeight = {
    heading: `1.05`,
    body: `1.5`,
}

const family = {
    primary: 'warnock-pro-display, serif',
    secondary: 'acumin-pro-semi-condensed, sans-serif',
}

const weights = {
    extraLight: '200',
    light: '300',
    regular: '400',
    bold: '700',
    black: '800',
}

export const typestyle = () => css`
    ${(props) => {
        switch (props.size ? props.size : 'body') {
            case 'cover':
                return `
                    font-family: ${family.secondary};
                    font-size: ${typescale.h3};
                    font-weight: ${weights.black};
                    line-height: ${lineHeight.heading};

                    @media ${BREAKPOINTS.mobile} {
                        font-size: ${typescale.display};
                    }
                `
            case 'display':
                return `
                    font-family: ${family.secondary};
                    font-size: ${typescale.h3};
                    font-weight: ${weights.black};
                    line-height: ${lineHeight.heading};

                    @media ${BREAKPOINTS.mobile} {
                        font-size: ${typescale.display};
                    }
                `
            case 'h1':
                return `
                    font-family: ${family.secondary};
                    font-size: ${typescale.h1};
                    font-weight: ${weights.black};
                    line-height: ${lineHeight.heading};
                `
            case 'h2':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.h3};
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};

                    @media ${BREAKPOINTS.mobile} {
                        font-size: ${typescale.h2};
                    }
                `
            case 'h3':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.h4};
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};

                    @media ${BREAKPOINTS.mobile} {
                        font-size: ${typescale.h3};
                    }
                `
            case 'h4':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.h5};
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};

                    @media ${BREAKPOINTS.mobile} {
                        font-size: ${typescale.h4};
                    }
                `
            case 'h5':
                return `
                    font-family: ${family.secondary};
                    font-size: ${typescale.h5};
                    font-weight: ${weights.black};
                    line-height: ${lineHeight.heading};
                `
            case 'h6':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.h6};
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};
                `
            case 'body':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.body};
                    font-weight: ${weights.light};
                    line-height: ${lineHeight.body};
                `
            case 'subtitle':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.subtitle};
                    font-weight: ${weights.light};
                    line-height: ${lineHeight.body};
                `
            case 'button':
                return `
                    font-family: ${family.secondary};
                    font-size: ${typescale.button};
                    font-weight: ${weights.extraLight};
                    line-height: ${lineHeight.heading};
                `
            case 'caption':
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.caption};
                    font-weight: ${weights.light};
                    line-height: ${lineHeight.heading};
                `
            default:
                return `
                    font-family: ${family.primary};
                    font-size: ${typescale.body};
                    font-weight: ${weights.light};
                    line-height: ${lineHeight.body};
                    text-decoration: underline;
                `
        }
    }}
`

export const P = styled.p`
    margin: ${(props) => (props.margin ? props.margin : `${Spacing.sm} 0`)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}

    &:empty {
        display: none;
    }
`

export const CAPTION = styled.p`
    margin: ${(props) => (props.margin ? props.margin : `${Spacing.sm} 0`)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'caption'
        return typestyle()
    }}
`

export const H1 = styled.h1`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'h1'
        return typestyle()
    }}
`

export const H2 = styled.h2`
    margin: ${(props) => (props.margin ? props.margin : `0 0 ${Spacing.sm} 0`)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'h2'
        return typestyle()
    }}
`

export const H3 = styled.h3`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'h3'
        return typestyle()
    }}
`

export const H4 = styled.h4`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'h4'
        return typestyle()
    }}
`

export const H5 = styled.h5`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'h5'
        return typestyle()
    }}
`

export const H6 = styled.h6`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'h6'
        return typestyle()
    }}
`

export const UL = styled.ul`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`

export const OL = styled.ol`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`

export const BLOCKQUOTE = styled.blockquote`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    padding: ${(props) => (props.padding ? props.padding : `0`)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`