import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'

import { BREAKPOINTS } from './shared/grid'
import Theme from './shared/colors'
import Spacing from './shared/spacing'

import ImageWithSVGSupport from './image-with-svg-support'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { H2, H3, P, UL, LI, typestyle } from './shared/typography'
import BlockExternalEmbed from './blocks/block-external-embed'
import TextLink from './shared/text-link'

const GlobalFooter = ({ scrollRef }) => {
    const data = useStaticQuery(graphql`
        {
            contentfulHomePage(slug: { eq: "brand-x" }) {
                id
                logo {
                    ...Asset
                }
                instagramUrl
                facebookUrl
                twitterUrl
                linkedInUrl
                privacyPolicy {
                    ...StandardPage
                }
            }
        }
    `)

    const siteSettings = data.contentfulHomePage

    const formUrl =
        'https://admin075583.typeform.com/to/hMJwaJWA?typeform-welcome=0'

    return (
        <Footer>
            <SocialLinks>
                {siteSettings.facebookUrl && (
                    <SocialLink>
                        <IconLink
                            href={siteSettings.facebookUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'facebook-f']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>Facebook</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
                {siteSettings.twitterUrl && (
                    <SocialLink>
                        <IconLink
                            href={siteSettings.twitterUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'twitter']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>Twitter</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
                {siteSettings.instagramUrl && (
                    <SocialLink>
                        <IconLink
                            href={siteSettings.instagramUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'instagram']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>Instagram</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
                {siteSettings.linkedInUrl && (
                    <SocialLink>
                        <IconLink
                            href={siteSettings.linkedInUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'linkedin']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>LinkedIn</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
            </SocialLinks>
            {siteSettings.logo && (
                <Logo
                    title={siteSettings.logo.title}
                    fluid={siteSettings.logo.fluid}
                    file={siteSettings.logo.file}
                />
            )}
            <Copyright size="caption">Brand X Cleveland &copy; 2020</Copyright>
            <Copyright size="caption">
                Questions submitted and answers provided may be showcased in
                whole or in part for marketing purposes by Brand X. Please refer
                to our <TextLink node={siteSettings.privacyPolicy} inverted={true}>Privacy Policy</TextLink> for details.
            </Copyright>
        </Footer>
    )
}

const Footer = styled.footer`
    padding: ${Spacing.xxxl} 10vw ${Spacing.xl} 10vw;
    width: 100vw;
    background-color: ${Theme.ui.primary};
    text-align: right;
    color: ${Theme.text.primary};

    @media ${BREAKPOINTS.mobile} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`

const Newsletter = styled.section`
    grid-column: span 2;
    text-align: center;
`

const Logo = styled(ImageWithSVGSupport)`
    display: inline-block;
    margin: ${Spacing.md} 0 0 0;
    max-width: 7rem;
    justify-self: end;
    align-self: end;
`

const SocialLinks = styled(UL)`
    margin: ${Spacing.md} 0 0 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @media ${BREAKPOINTS.mobile} {
        text-align: left;
    }
`
const SocialLink = styled.li`
    display: inline-block;
    margin: 0 ${Spacing.xxs};
    padding: 0;
    background-color: ${Theme.ui.inverse};
    border-radius: 0.9rem;
    list-style: none;
`

const IconLink = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    color: ${Theme.ui.primary};

    &:hover {
        color: ${Theme.text.primary};
    }
`

const Icon = styled(FontAwesomeIcon)`
    display: inline-block;
`

const SocialName = styled.span`
    position: absolute;
    left: -999em;
    display: block;
`

const Copyright = styled(P)`
    margin-top: ${Spacing.xs};

    @media ${BREAKPOINTS.mobile} {
        grid-column-start: 2;
    }
`

export default GlobalFooter
