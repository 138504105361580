import React, { useState, useCallback, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as typeformEmbed from '@typeform/embed' // https://www.npmjs.com/package/@typeform/embed
import { Helmet, HelmetProvider } from 'react-helmet-async'
import styled, { css } from 'styled-components'


import { BREAKPOINTS } from '../shared/grid'
import { P } from '../shared/typography'
import TextLink from '../shared/text-link'

const BlockExternalEmbed = ({
    displayTitle,
    url,
    html,
    popup,
    externalScript,
    className,
}) => {
    const data = useStaticQuery(graphql`
        {
            contentfulHomePage(slug: { eq: "brand-x" }) {
                id
                privacyPolicy {
                    ...StandardPage
                }
            }
        }
    `)

    const siteSettings = data.contentfulHomePage

    const hasSource = typeof url !== 'undefined' && url !== null
    const hasHTML = typeof html !== 'undefined' && html !== null
    const isPopup = typeof popup !== 'undefined' && popup === true
    const callToAction =
        typeof displayTitle !== 'undefined' && displayTitle !== null
            ? displayTitle
            : 'Submit'

    if (hasSource) {
        if (url.includes('typeform.com/to/') && isPopup) {
            const popup = typeformEmbed.makeWidget(url, {
                mode: 'drawer_right',
                // open: 'scroll',
                // openValue: 30,
                // autoClose: 3,
                hideScrollbars: true,
                onSubmit: function () {},
                onReady: function () {},
                onClose: function () {},
            })

            popup.open()
        } else if (url.includes('typeform.com/to/') && !isPopup) {
            const embedRef = useCallback((node) => {
                if (node !== null) {
                    const embed = typeformEmbed.makeWidget(node, url, {
                        hideHeaders: true,
                        hideFooter: true,
                        opacity: 0,
                        hideScrollbars: true,
                        onSubmit: function () {},
                        onReady: function () {},
                    })
                }
            }, [])

            return (
                <EmbedContainer>
                    <TypeFormContainer
                        className={className}
                        ref={embedRef}
                    ></TypeFormContainer>
                    <P size="caption">
                        By registering, you agree to the processing of your
                        personal data by Brand X as described in the{' '}
                        <TextLink
                            node={siteSettings.privacyPolicy}
                            inverted={true}
                        >
                            Privacy Policy
                        </TextLink>
                        .
                    </P>
                </EmbedContainer>
            )
        } else {
            return <iframe title={callToAction} src={url} />
        }
    } else if (hasHTML) {
        const hasScript =
            typeof externalScript !== 'undefined' && externalScript !== null
        return (
            <div>
                {hasScript && (
                    <Helmet>
                        <script src={externalScript} type="text/javascript" />
                    </Helmet>
                )}
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        )
    } else {
        return <span></span>
    }
}

const TypeFormContainer = styled.div`
    height: 50vh;
`

const EmbedContainer = styled.div`
    padding: 0;
    text-align: center;

    @media ${BREAKPOINTS.mobile} {
        padding: 0 10vw;
    }
`

const TypeFormPopup = (url) => {
    const hasSource = typeof url !== 'undefined' && url !== null

    if (hasSource) {
        if (url.includes('typeform.com/to/')) {
            const popup = typeformEmbed.makePopup(url, {
                mode: 'drawer_right',
                // open: 'scroll',
                // openValue: 30,
                // autoClose: 3,
                hideScrollbars: true,
                onSubmit: function () {},
                onReady: function () {},
                onClose: function () {},
            })

            popup.open()
        }
    }
}

export { BlockExternalEmbed as default, TypeFormPopup }
