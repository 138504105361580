import styled, { css } from 'styled-components'
import Spacing from './spacing'

const size = {
    small: '425px',
    medium: '768px',
    large: '1024px',
    extra: '1440px',
}

export const BREAKPOINTS = {
    mobile: `(min-width: ${size.small})`,
    tablet: `(min-width: ${size.medium})`,
    laptop: `(min-width: ${size.large})`,
    desktop: `(min-width: ${size.extra})`,
}

export const GRIDS = {
    content: () => css`
        grid-template-columns: [left-gutter] 2fr [left-main] 1fr [main] 3fr [half-main] 3fr [right-main] 1fr [right-gutter] 2fr;
        grid-column-gap: ${() => `${Spacing.xxxl}`};
        grid-row-gap: 0px;
    `,
    home: () => css`
        grid-template-columns: [left-gutter] 1fr [main] 8fr [half-main] 8fr [right-gutter] 1fr;
        grid-column-gap: ${() => `${Spacing.xxxl}`};
        grid-row-gap: 0px;
    `,
}
