import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

import { BREAKPOINTS } from './shared/grid'
import Navigation from './global-navigation'
import Spacing from './shared/spacing'
import Theme from './shared/colors'

import { Link as GatsbyLink } from 'gatsby'
import ImageWithSVGSupport from './image-with-svg-support'

const GlobalHeader = ({ title }) => {
    const data = useStaticQuery(graphql`
        {
            contentfulHomePage(slug: { eq: "brand-x" }) {
                id
                logo {
                    ...Asset
                }
            }
        }
    `)

    const siteSettings = data.contentfulHomePage

    return (
        <Header>
            {siteSettings.logo && (
                <GatsbyLink to={'/'}>
                    <Logo
                        title={siteSettings.logo.title}
                        fluid={siteSettings.logo.fluid}
                        file={siteSettings.logo.file}
                    />
                </GatsbyLink>
            )}
            <Navigation />
        </Header>
    )
}

const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${Spacing.xxl} 10vw;
    width: 100vw;
    background-color: ${Theme.ui.primary};
    color: ${Theme.text.brand};
    z-index: 1000;

    @media ${BREAKPOINTS.mobile} {
        position: absolute;
        background-color: transparent;
    }
`

const Logo = styled(ImageWithSVGSupport)`
    max-width: 7rem;
`

export default GlobalHeader
