import React, { useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useSpring, config } from '@react-spring/core'
import { a } from '@react-spring/web'
import styled, { css } from 'styled-components'
import * as typeformEmbed from '@typeform/embed' // https://www.npmjs.com/package/@typeform/embed

import Theme from './colors'
import Spacing from './spacing'

const linkResolver = require('../../utils').linkResolver

const handleFormPopup = url => {
    const popup = typeformEmbed.makePopup(url, {
        mode: 'drawer_right',
        // open: 'scroll',
        // openValue: 30,
        // autoClose: 3,
        hideScrollbars: true,
        onSubmit: function() {},
        onReady: function() {},
        onClose: function() {},
    })

    popup.open()
}

export default ({ node, uri, children, formUrl, inverted }) => {
    const isExternal = typeof uri !== 'undefined' && uri !== null
    const [hover, toggleHover] = useState(0)
    const hoverState = typeof isHovered === 'undefined' ? hover : isHovered
    const to = !uri ? linkResolver.path(node) : uri
    const isInverted = typeof inverted !== 'undefined' ? inverted : 1

    const isForm = typeof formUrl !== 'undefined' && formUrl !== null

    const { x } = useSpring({
        x: hoverState,
    })

    if (isExternal) {
        return (
            <Link
                rel="noopener noreferrer"
                href={to}
                onMouseEnter={() => toggleHover(1)}
                onMouseLeave={() => toggleHover(0)}
            >
                <HoverText
                    aria-hidden="hidden"
                    style={{
                        clipPath: x.to(
                            [1, 0],
                            [
                                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                                'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                            ]
                        ),
                    }}
                    inverted={isInverted}
                >
                    {children}
                </HoverText>
                <HoverUnderline
                    aria-hidden="hidden"
                    style={{
                        y: x.to([0, 1], ['0em', '-0.25em']),
                        opacity: x.to([0, 1], [1, 0]),
                    }}
                    inverted={isInverted}
                ></HoverUnderline>
                <MainText
                    style={{
                        clipPath: x.to(
                            [1, 0],
                            [
                                'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
                                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                            ]
                        ),
                    }}
                >
                    {children}
                </MainText>
            </Link>
        )
    } else if (!isExternal & !isForm) {
        return (
            <InternalLink
                to={to}
                onMouseEnter={() => toggleHover(1)}
                onMouseLeave={() => toggleHover(0)}
            >
                <HoverText
                    aria-hidden="hidden"
                    style={{
                        clipPath: x.to(
                            [1, 0],
                            [
                                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                                'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                            ]
                        ),
                    }}
                    inverted={isInverted}
                >
                    {children}
                </HoverText>
                <HoverUnderline
                    aria-hidden="hidden"
                    style={{
                        y: x.to([0, 1], ['0em', '-0.25em']),
                        opacity: x.to([0, 1], [1, 0]),
                    }}
                    inverted={isInverted}
                ></HoverUnderline>
                <MainText
                    style={{
                        clipPath: x.to(
                            [1, 0],
                            [
                                'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
                                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                            ]
                        ),
                    }}
                >
                    {children}
                </MainText>
            </InternalLink>
        )
    } else if (!isExternal && isForm) {

        return (
            <FormLink
                onMouseEnter={() => toggleHover(1)}
                onMouseLeave={() => toggleHover(0)}
                onClick={() => handleFormPopup(formUrl)}
            >
                <HoverText
                    aria-hidden="hidden"
                    style={{
                        clipPath: x.to(
                            [1, 0],
                            [
                                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                                'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
                            ]
                        ),
                    }}
                    inverted={isInverted}
                >
                    {children}
                </HoverText>
                <HoverUnderline
                    aria-hidden="hidden"
                    style={{
                        y: x.to([0, 1], ['0em', '-0.25em']),
                        opacity: x.to([0, 1], [1, 0]),
                    }}
                    inverted={isInverted}
                ></HoverUnderline>
                <MainText
                    style={{
                        clipPath: x.to(
                            [1, 0],
                            [
                                'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
                                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                            ]
                        ),
                    }}
                >
                    {children}
                </MainText>
            </FormLink>
        )
    }
}

const Link = styled.a`
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;

    &:link,
    &:active {
        color: currentColor;
    }

    &:visited {
        color: currentColor;
    }

    u {
        text-decoration: none;
    }
`

const InternalLink = styled(GatsbyLink)`
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;

    &:link,
    &:active {
        color: currentColor;
    }

    &:visited {
        color: currentColor;
    }

    u {
        text-decoration: none;
    }
`

const FormLink = styled.span`
    position: relative;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;

    &:link,
    &:active {
        color: currentColor;
    }

    &:visited {
        color: currentColor;
    }

    u {
        text-decoration: none;
    }
`

const MainText = styled(a.span)`
    display: inline-block;
    position: relative;
    z-index: 1;
    color: hsl(222deg, 22%, 5%);
    text-decoration: none;
    color: currentColor;
`

const HoverText = styled(a.span)`
    display: inline-block;
    position: absolute;
    z-index: 2;
    left: 0px;
    user-select: none;
    color: ${props => props.inverted === 1 ? Theme.text.inverse : Theme.text.hover};
`

const HoverUnderline = styled(a.span)`
    position: absolute;
    left: -2px;
    right: -2px;
    bottom: 0;
    height: 2px;
    background-color: ${props => props.inverted === 1 ? Theme.text.inverse : Theme.text.hover};
    border-radius: 2px;
    content: "";
`