import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { useTrail, a } from 'react-spring'

import { BREAKPOINTS } from './shared/grid'
import Theme from './shared/colors'
import Spacing from './shared/spacing'
import TextLink from './shared/text-link'

import { P, typestyle } from './shared/typography'

const GlobalNavigation = ({ scrollRef }) => {
    const data = useStaticQuery(graphql`
        {
            contentfulHomePage(slug: { eq: "brand-x" }) {
                id
                primaryNavigation {
                    ...NavigationItem
                }
            }
        }
    `)

    const [open, setOpen] = useState(false)

    const navigationItems = get(data, 'contentfulHomePage.primaryNavigation')

    const trail = useTrail(navigationItems.length, {
        config: { mass: 5, tension: 2000, friction: 200 },
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        heightEx: open ? 3 : 0,
        from: { opacity: 0, x: 20, heightEx: 0 },
    })

    return (
        <Navigation>
            <Open open={open} onClick={() => setOpen(!open)} src={ open ? '/logo-icon.svg' : '/navigation-open.svg'} />
            {open && (
                <Items>
                    {trail.map(({ x, heightEx, ...rest }, index) => (
                        <Item
                            key={navigationItems[index].id + '-container'}
                            style={{
                                ...rest,
                                transform: x.to(
                                    (x) => `translate3d(0,${x}px,0)`
                                ),
                            }}
                        >
                            <P size={'h2'}>
                                <a.span
                                    style={{
                                        display: 'inline-block',
                                        height: heightEx.to(
                                            (heightEx) => `${heightEx}ex`
                                        ),
                                    }}
                                >
                                    <TextLink
                                        node={
                                            navigationItems[index].internalLink
                                        }
                                        uri={navigationItems[index].externalUrl}
                                        inverted={0}
                                    >
                                        {navigationItems[index].displayTitle}
                                    </TextLink>
                                </a.span>
                            </P>
                        </Item>
                    ))}
                </Items>
            )}
        </Navigation>
    )
}

const Navigation = styled.nav`
    margin: 0;
    padding: 0;
    text-align: right;
    color: ${Theme.text.primary};
`

const Items = styled.ul`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    align-items: center;
    margin: 0;
    padding: ${Spacing.xxxl} 10vw ${Spacing.xl} 10vw;
    border: 1vw solid ${Theme.brand.primary};
    background-color: ${Theme.ui.primary};
    text-align: left;
    z-index: 10;

    @media ${BREAKPOINTS.mobile} {
        display: grid;
    }
`

const Item = styled(a.li)`
    margin: 0;
    padding: ${Spacing.xxxs} 0;
    ${(props) => {
        props.size = props.size ? props.size : 'h2'
        return typestyle()
    }}
    list-style: none;
`

const Close = styled.img`
    position: absolute;
    top: ${Spacing.md};
    right: ${Spacing.md};
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 100;
`

const Open = styled.img`
    position: ${(props) => props.open ? 'fixed' : 'relative'};
    right: ${(props) => props.open ? '10vw' : '0'};
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 1000;
`

export default GlobalNavigation
